import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "./contexts/AuthContext"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  let value='/';
  const location = useLocation();
  if (location.state && location.state.value)
    value=location.state.value;
  return (
    <Route
      {...rest}
      render={props => {
        return currentUser===null ? <Component {...props} /> : <Redirect to={value} />
      }}
    ></Route>
  )
}
