export const ADD_TO_ENQUIRY = "ADD_TO_ENQUIRY";
export const DELETE_FROM_ENQUIRY = "DELETE_FROM_ENQUIRY";
export const DELETE_ALL_FROM_ENQUIRY = "DELETE_ALL_FROM_ENQUIRY";
export const UPDATE_ENQUIRY_LANG = "UPDATE_ENQUIRY_LANG";
// add to wishlist
export const addToEnquiry = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Added To Enquiry", {
        appearance: "success",
        autoDismiss: true
      });
    }
    dispatch({ type: ADD_TO_ENQUIRY, payload: item });
  };
};

// delete from wishlist
export const deleteFromEnquiry = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Enquiry", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_FROM_ENQUIRY, payload: item });
  };
};

//delete all from wishlist
export const deleteAllFromEnquiry = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Enquiry", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_ENQUIRY });
  };
};


export const updateEnquiryLang = (item) => {
  return dispatch => {
    dispatch({ type: UPDATE_ENQUIRY_LANG, payload: item });
  };
}; 
