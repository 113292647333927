export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
//export const UPDATE_PRODUCTS_LANG = "UPDATE_PRODUCTS_LANG";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = products => {
  return dispatch => {
    dispatch(fetchProductsSuccess(products));
  };
};

//export const updateProductsLang = (item) => {
//  return dispatch => {
//    dispatch({ type: UPDATE_PRODUCTS_LANG, payload: item });
//  };
//}; 
