import {
  ADD_TO_WISHLIST,
  DELETE_FROM_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
  UPDATE_WISHLIST_LANG
} from "../actions/wishlistActions";

const initState = [];

const wishlistReducer = (state = initState, action) => {
  const wishlistItems = state,
    product = action.payload;

   if (action.type === ADD_TO_WISHLIST) {
    const wishlistItem = wishlistItems.filter(
      item => item.id === product.id
    )[0];
    if (wishlistItem === undefined) {
      return [...wishlistItems, product];
    } else {
      return wishlistItems;
    }
  }

  if (action.type === DELETE_FROM_WISHLIST) {
    const remainingItems = (wishlistItems, product) =>
      wishlistItems.filter(wishlistItem => wishlistItem.id !== product.id);
    return remainingItems(wishlistItems, product);
  }

  if (action.type === DELETE_ALL_FROM_WISHLIST) {
    return wishlistItems.filter(item => {
      return false;
    });
  }

  if (action.type === UPDATE_WISHLIST_LANG) {  
 /*   console.log(wishlistItems)
    wishlistItems= wishlistItems.filter(item =>
      product.some(single => 
        single.id ===item.id)); */

    wishlistItems.forEach(item =>      
       product.filter(allitem => 
       {
        if (item.id === allitem.id)       
        {
        // item.name=allitem.name;
        // item.category= allitem.category;
        // item.tag= allitem.tag;
         item.price= allitem.price;
         item.discount=allitem.discount;    
         item.stock=allitem.stock;   
        }
        return item;
      }
      ))       
     // wishlistItems[index]=allitem;
      return wishlistItems;
    }
  return wishlistItems;
};

export default wishlistReducer;
