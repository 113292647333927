import React, { useContext, useState, useEffect } from "react"
import { auth } from "../firebase"
import { EmailAuthProvider } from "firebase/auth" 
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { sendPasswordResetEmail } from "firebase/auth";
const AuthContext = React.createContext()

/*
const getUserInfo = async (email) => {
  let retries = 0;
  const data=  {action: "userinfo", type: "myaccount", email: email}
  while (retries < MAX_RETRIES) {
    try {
      const response = await axios.post(process.env.REACT_APP_APIURL,data)
        return response.data;
    } catch (error) {
      retries++;
      if (retries === MAX_RETRIES) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
    }
  }
}; */

const getUserInfo = async (email) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    timeout: 6000,
  });
  axiosRetry(axiosInstance, { retries: 3 });
  const data=  {action: "userinfo", type: "myaccount", email: email}
  const response = await axiosInstance.post('/csadmin.php', data)
    return response.data;  
  }      

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  /*const [myDetail, setMyDetail] = useState({
    name: "",
    title: "",
    mobile: ""
  }) */
  const [myDetail, setMyDetail] = useState(() => {
    const localValue=localStorage.getItem("MYDETAIL")
    if (localValue==null || localValue=="undefined") return { name: "", title: "", mobile: ""}
     return JSON.parse(localValue);
  })

  const [myAddress, setMyAddress] = useState(() => {
    const localAddress=localStorage.getItem("MYADDRESS")
    if (localAddress==null || localAddress=='undefined') return [] 
     return JSON.parse(localAddress);
  })

  const [myOrder, setMyOrder] = useState(() => {
    const localOrder=localStorage.getItem("MYORDER")
    if (localOrder==null || localOrder=='undefined') return [] 
     return JSON.parse(localOrder);
  })  

  //const [myWishlist, setMyWishList] = useState([]);


  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    setMyDetail((prevDetail)=> ({...prevDetail, name: "", title: "", mobile: ""}));
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    setMyDetail((prevDetail)=> ({...prevDetail, name: "", title: "", mobile: ""}));
    return auth.signOut()
  }

  function resetPassword(email) {
   // return auth.sendPasswordResetEmail(email)
    return sendPasswordResetEmail(auth, email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  function reauthenticate(currentPassword) {
    let credential=EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    return currentUser.reauthenticateWithCredential(credential);
  }

  function updateMyDetail(name, title, mobile) {
    return setMyDetail((prevDetail)=> ({...prevDetail, name: name, title: title, mobile: mobile}));
  //  return currentUser.updateProfile(name,"")
  }

  function updateMyAddress(address) {
    return setMyAddress(() => address );
  }

  function updateMyOrder(order) {
    return setMyOrder(() => order );
  }  

  useEffect(() => {
   // console.log("auth")
    const unsubscribe = auth.onAuthStateChanged(user => {
     // console.log(user)
      setCurrentUser(null)
      if (user===null)
      {
       setMyDetail((prevDetail)=> ({...prevDetail, name: "", title: "", mobile: ""}));
       setMyAddress([]);
      // setMyWishList([]);
      }
      else
      {
        getUserInfo(user.email).then(data => {
         /* if (data.wishlist!==undefined)
          {
            setMyWishList(()=> (data.wishlist))
          } */         
        //  console.log(data) 
          if (data.user!==undefined)      
          {
             setMyDetail((prevDetail)=> ({...prevDetail, name: data.user.name, title: data.user.title, mobile: data.user.mobile}));   
          }
          if (data.address!==undefined)
          {
            setMyAddress(()=> (data.address ))
          }
          if (data.order!==undefined)
          {
            setMyOrder(() => (data.order))
          }
          setCurrentUser(user);
        })
        .catch(error => {
          // handle error
           console.log(error.message)
           setCurrentUser(null);
        })
     //   .finally(() => {
      //    setCurrentUser(user)
      //  })
      }
      setLoading(false);
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    localStorage.setItem("MYDETAIL", JSON.stringify(myDetail))
  },[myDetail])

  useEffect(() => {
    localStorage.setItem("MYADDRESS",JSON.stringify(myAddress))
  },[myAddress])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    reauthenticate,
    myDetail,
    updateMyDetail,
    myAddress,
     updateMyAddress,
     myOrder,
     updateMyOrder,    
     getUserInfo
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
