import {
    ADD_TO_ENQUIRY,
    DELETE_FROM_ENQUIRY,
    DELETE_ALL_FROM_ENQUIRY,
    UPDATE_ENQUIRY_LANG
  } from "../actions/enquiryActions";
  
  const initState = [];
  
  const enquiryReducer = (state = initState, action) => {
    const enquiryItems = state,
      product = action.payload;
  
     if (action.type === ADD_TO_ENQUIRY) {
      const enquiryItem = enquiryItems.filter(
        item => item.id === product.id
      )[0];
      if (enquiryItem === undefined) {
        return [...enquiryItems, product];
      } else {
        return enquiryItems;
      }
    }
  
    if (action.type === DELETE_FROM_ENQUIRY) {
      const remainingItems = (enquiryItems, product) =>
        enquiryItems.filter(enquiryItem => enquiryItem.id !== product.id);
      return remainingItems(enquiryItems, product);
    }
  
    if (action.type === DELETE_ALL_FROM_ENQUIRY) {
      return enquiryItems.filter(item => {
        return false;
      });
    }
  
    if (action.type === UPDATE_ENQUIRY_LANG) {  
     
      enquiryItems.forEach(item =>      
         product.filter(allitem => 
         {
          if (item.id === allitem.id)       
          {
        //   item.name=allitem.name;
        //   item.category= allitem.category;
        //   item.tag= allitem.tag;
           item.price= allitem.price;
           item.discount=allitem.discount;
           item.stock=allitem.stock;          
          }
          return item;
        }
        ))       
       // enquiryItems[index]=allitem;
        return enquiryItems;
      }
  
  
    return enquiryItems;
  };
  
  export default enquiryReducer;
  