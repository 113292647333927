//import PropTypes from "prop-types";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = (props) => {
  useEffect(() => {    
    const lasttime=localStorage.getItem("refreshTime")
    const curtime=new Date()
   // console.log("scrolltop")
    if (lasttime===undefined || (curtime.getTime()-lasttime)>1000 ) {
      window.scrollTo(0, 0);
    //  console.log(lasttime)
    //  console.log(curtime.getTime())
    }
  });
  return props.children;
};


export default withRouter(ScrollToTop);
